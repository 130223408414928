import * as React from 'react'

import Logout from '@mui/icons-material/Logout'
import Settings from '@mui/icons-material/Settings'
import Avatar from '@mui/material/Avatar'
import Box from '@mui/material/Box'
import Divider from '@mui/material/Divider'
import IconButton from '@mui/material/IconButton'
import ListItemIcon from '@mui/material/ListItemIcon'
import Menu from '@mui/material/Menu'
import MenuItem from '@mui/material/MenuItem'
import Tooltip from '@mui/material/Tooltip'
import { useTranslation } from 'react-i18next'
import { BsBuilding, BsNewspaper } from 'react-icons/bs'
import { TbSchool } from 'react-icons/tb'
import { useNavigate } from 'react-router-dom'

interface props {
  userName?: string
  userLogo?: string
  logout?: any
  email?: string
  companyName?: string
  tooltip: string
}

export default function AccountMenu({
  userName,
  userLogo,
  logout,
  email,
  companyName,
  tooltip,
}: props) {
  const { t } = useTranslation('layout')
  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null)
  const open = Boolean(anchorEl)
  const navigate = useNavigate()
  const handleClick = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget)
  }
  const handleClose = () => {
    setAnchorEl(null)
  }
  return (
    <>
      <Box sx={{ display: 'flex', alignItems: 'center', textAlign: 'center' }}>
        <Tooltip title={tooltip}>
          <IconButton
            onClick={handleClick}
            size='small'
            sx={{ ml: 2 }}
            aria-controls={open ? 'account-menu' : undefined}
            aria-haspopup='true'
            aria-expanded={open ? 'true' : undefined}
          >
            <Avatar sx={{ width: 32, height: 32 }} src={userLogo}>
              {userName}
            </Avatar>
          </IconButton>
        </Tooltip>
      </Box>
      <Menu
        anchorEl={anchorEl}
        id='account-menu'
        open={open}
        onClose={handleClose}
        onClick={handleClose}
        PaperProps={{
          elevation: 0,
          sx: {
            overflow: 'visible',
            filter: 'drop-shadow(0px 2px 8px rgba(0,0,0,0.32))',
            mt: 1.5,
            '& .MuiAvatar-root': {
              width: 32,
              height: 32,
              ml: -0.5,
              mr: 1,
            },
            '&:before': {
              content: '""',
              display: 'block',
              position: 'absolute',
              top: 0,
              right: 14,
              width: 10,
              height: 10,
              bgcolor: 'background.paper',
              transform: 'translateY(-50%) rotate(45deg)',
              zIndex: 0,
            },
          },
        }}
        transformOrigin={{ horizontal: 'right', vertical: 'top' }}
        anchorOrigin={{ horizontal: 'right', vertical: 'bottom' }}
      >
        <MenuItem>
          <Avatar sx={{ width: 32, height: 32 }} src={userLogo}>
            {userName}
          </Avatar>{' '}
          <div>
            <b>{companyName}</b>
            <br />
            <span className='text-small'>{email}</span>
          </div>
        </MenuItem>
        <Divider />
        <MenuItem onClick={() => navigate('/communication')}>
          <ListItemIcon>
            <BsNewspaper />
          </ListItemIcon>
          {t('header.communication')}
        </MenuItem>
        <MenuItem onClick={() => navigate('/training')}>
          <ListItemIcon>
            <TbSchool />
          </ListItemIcon>
          {t('header.training')}
        </MenuItem>
        <MenuItem onClick={() => navigate('/company')}>
          <ListItemIcon>
            <BsBuilding />
          </ListItemIcon>
          {t('header.company')}
        </MenuItem>
        <MenuItem onClick={() => navigate('/settings')}>
          <ListItemIcon>
            <Settings fontSize='small' />
          </ListItemIcon>
          {t('header.settings')}
        </MenuItem>
        <MenuItem onClick={logout}>
          <ListItemIcon>
            <Logout fontSize='small' />
          </ListItemIcon>
          {t('header.logout')}
        </MenuItem>
      </Menu>
    </>
  )
}
