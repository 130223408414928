import { useState } from 'react'

import { Skeleton } from '@mui/material'
import { useAsync, useFetchAndLoad } from 'hooks'
import { useTranslation } from 'react-i18next'
import { getTotalData } from 'services'
import { kmsToMiles, nullToCero } from 'utilities'

import './TotalsTable.scss'

const emptyTotals = {
  kms: -1,
  co2: 0,
  ciclos: 0,
  money_saving: 0,
  active_users: 0,
  total_activities: 0,
}

export default function TotalsTable() {
  const { callEndpoint } = useFetchAndLoad()
  const { t, i18n } = useTranslation('layout')
  const [data, setData] = useState<any>(emptyTotals)

  const getTotals = async () =>
    await callEndpoint(getTotalData('', '', '', '', '', '', 'all'))
  const saveTotalData = (data: any) => {
    setData(data)
  }
  useAsync(getTotals, saveTotalData, () => {})

  let dataList: any[] = []

  if (data)
    dataList = [
      {
        img: '/../kms.png',
        total:
          i18n.language === 'en'
            ? kmsToMiles(nullToCero(data?.kms))
            : nullToCero(data?.kms),
        unit: 'kms',
      },
      {
        img: '/../co2.png',
        total: `${nullToCero(data?.co2)} KG`,
        unit: 'co2',
      },
      {
        img: '/../ciclos.png',
        total: nullToCero(data?.ciclos_total),
        unit: 'ciclos',
      },
      {
        img: '/../money.png',
        total: `${nullToCero(data?.money_saving)} ${
          i18n.language === 'en' ? '$' : '€'
        }`,
        unit: 'money',
      },
      {
        img: '/../participants.png',
        total: nullToCero(data?.handled_users),
        unit: 'participants',
      },
      {
        img: '/../activities.png',
        total: nullToCero(data?.total_activities),
        unit: 'activities',
      },
    ]

  return (
    <>
      {data?.kms > -1 ? (
        <div className='total-table'>
          <h3 className='text-big tc'>{t('drawer.totals')}</h3>
          {dataList.map((item, idx) => {
            return (
              <div key={idx} className='total-table_item'>
                <img
                  className='total-table_item-img'
                  alt={item.unit}
                  src={item.img}
                />
                <div className='total-table_item_totals'>
                  <b className='total-table_item_totals-value'>{item.total}</b>
                  <span className='total-table_item_totals-unit'>
                    {t(`drawer.${item.unit}`)}
                  </span>
                </div>
              </div>
            )
          })}
        </div>
      ) : (
        <Skeleton variant='rounded' height={385} />
      )}
    </>
  )
}
